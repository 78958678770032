
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatableLeft.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import EntityModal from "@/components/modals/forms/entity/EntityModal.vue";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { VueCookieNext } from "vue-cookie-next";

export default defineComponent({
  name: "pending-map-trainer-institute-list",
  components: {
    EntityModal,
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      actionApprove: false,
      actionEdit: false,
      actionReject: false,
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },

        {
          name: "Trainer",
          key: "trainer",
          sortable: true,
        },
        {
          name: "Institute",
          key: "institute",
          sortable: true,
        },
      ],
      componentkey: 0,
      tableData: [
        // {
        //   id: 1,
        //   trainer: {
        //     name: "Zohirul Alam Tiemoon",
        //     email: "tiemoon@gmail.com",
        //     phone: "+8801756888319",
        //     gender: "Male",
        //   },
        //   institute: {
        //     name: "A.B.C Bangladesh-Turkish Technical School",
        //     email: "abcbdtktschool@yahoo.com",
        //     phone: "0312552697",
        //     website: "www.doulatpurabchighschool.edu.bd",
        //     address: "Nazirhat Municipality ,Fatickchari",
        //   },
        // },
        // {
        //   id: 2,
        //   trainer: {
        //     name: "Md. Nahidur Rahman Rifath",
        //     email: "nrifath2009@gmail.com",
        //     phone: "+8801672089753",
        //     gender: "Male",
        //   },
        //   institute: {
        //     name: "ACA Technical Institute",
        //     email: "aca.seip@gmail.com",
        //     phone: "+8801875889764",
        //     website: "www.aca.org",
        //     address: "Faterpur, Enayetnagar, Matlab Uttor, Chandpur",
        //   },
        // },
      ],
      loading: true,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.MapTrainerInstituteList();
    await this.actioncheck();
    this.emitter.on("mapInstitute-updated", async () => {
      await this.MapTrainerInstituteList();
    });
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem("menu") || "{}");
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == "Trainer") {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === "Approve") {
                this.actionApprove = true;
              }
              if (actions[j].action_name === "Edit") {
                this.actionEdit = true;
              }
              if (actions[j].action_name === "Reject") {
                this.actionReject = true;
              }
            }
          }
        }
      }
    },
    Approve(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: "Are you sure you want to Approve it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approve!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update("entity/trainer/map-status-change/" + `${id}`, data)
            .then((response) => {
              this.emitter.emit("mapInstitute-updated", true);
              Swal.fire("Approved!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Suspend(id) {
      let data = {
        active_status: 2,
      };
      Swal.fire({
        title: "Are you sure you want to suspend it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Suspend!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update("entity/trainer/map-status-change/" + `${id}`, data)
            .then((response) => {
              this.emitter.emit("mapInstitute-updated", true);
              Swal.fire("Suspended!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },

    async MapTrainerInstituteList() {
      let entity_id = "";
      if (VueCookieNext.getCookie("_seip_entity_type") != 1004) {
        entity_id = VueCookieNext.getCookie("_entity_id");
      }
      let mapping_type = "1";
      let active_status = 0;
      await ApiService.get(
        "entity/trainer/map_trainer_info?mapping_type=" +
          mapping_type +
          "&active_status=" +
          active_status +
          "&entity_id=" +
          entity_id
      )
        .then((response) => {
          this.tableData = response.data.data;
          console.log(this.tableData);
          this.loading = false;
          this.componentkey += 1;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    edit(data) {
      this.emitter.emit("edit-modal-data", data);
    },

    add() {
      this.emitter.emit("add-modal-data", true);
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
